import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem = ({ id, idx, value, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: "10px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "10px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="box box-solid">
        <div className="box-body">
          <button type="button" onClick={() => onRemove(idx)}>
            Verwijderen
          </button>
          <div className="media">
            <div className="media-left">
              <img
                src={value.thumbnail.small}
                className="media-object"
                style={{
                  flex: 1,
                  objectFit: "cover",
                  width: 200,
                  height: 150,
                  borderRadius: 4,
                  boxShadow: "0 1px 3px rgba(0,0,0,.15)",
                }}
              />
            </div>
            <div className="media-body">
              <h4 style={{ marginTop: 0 }}>{value.titel}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortableItem;