

import React from "react";
import { required, format } from "redux-form-validators";
import { Field } from "redux-form";
import { renderAFieldSimple, renderselectsimple } from "../../../Components/Forms/renders";
class optiesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { fields } = this.props;

    return (
      <tbody>
        {fields.map((opties, index) => (
          <tr key={index}>
            <td>
              <Field key={index} name={`${opties}.naam`} type="text" validate={format({ with: /^[a-z]+$/i, message: "Mag slechts een woord zijn" })} component={renderAFieldSimple} label="Naam van de optie regel" />
            </td>
            <td>
              <Field key={index} name={`${opties}.beschrijving`} type="text" validate={required({ msg: "Dit item is verplicht" })} component={renderAFieldSimple} label="Beschrijving van de optie regel" />
            </td>
            <td>
              <Field
                key={index}
                name={`${opties}.optie`}
                type="number"
                component={renderselectsimple}
                options={{
                  vraag: "Optie ja en nee",
                  aantal: "Aantallen",
                }}
                label="Opties"
              />
            </td>
            <td>
              <Field key={index} name={`${opties}.prijs`} type="number" component={renderAFieldSimple} label="Prijs" />
            </td>
            <td>
              <button onClick={() => fields.remove(index)}>
                <i className="fa fa-minus-circle"></i>
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <button type="button" onClick={() => fields.push({})}>
              optie regel Toevoegen
            </button>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default optiesForm;
