import { createRoot } from "react-dom/client";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Map from "ol/Map.js";
import View from "ol/View.js";
import ImageLayer from "ol/layer/Image.js";
import Overlay from "ol/Overlay.js";
import Static from "ol/source/ImageStatic.js";
import Projection from "ol/proj/Projection.js";
import { getCenter } from "ol/extent.js";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import { defaults as defaultInteractions } from 'ol/interaction';
import { Icon, Style } from "ol/style.js";
import { fetchsensoren, editsensor } from "../Reducers/actions";
import Loading from "../../../Components/loading/load";
const ImageMapEdit = ({ fetchsensoren, sensoren, editsensor, navigate, user }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const popupRef = useRef(null);
  const [position, setpositon] = useState([0, 0]);
  const nameRef = useRef(null);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      await fetchsensoren();
      setLoad(true);
    };
    if (user.bedrijf && user.bedrijf.plattegrond) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    const extent = [0, 0, 1024, 968];
    const projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: extent,
    });

    const markerData = sensoren
      .filter((value) => value.position && value.position[0] && value.position[1])
      .map((value, key) => ({
        position: [value.position[0], value.position[1]],
        icon: value.status !== undefined && value.status === "false" ? "/img/marker-bad.png" : "/img/marker-good.png",
        height: value.status !== undefined && value.status === "false" ? 50 : 30,
        width: value.status !== undefined && value.status === "false" ? 50 : 30,
        info: (
          <div className="popup-content">
            <h5>{value.naam}</h5>
          </div>
        ),
      }));

    const markerFeatures = markerData.map((marker) => {
      const markerFeature = new Feature({
        geometry: new Point(marker.position),
      });

      const markerStyle = new Style({
        image: new Icon({
          src: marker.icon,
          height: marker.height,
          width: marker.width,
          anchor: [0.5, 1],
        }),
      });

      markerFeature.setStyle(markerStyle);
      markerFeature.set("info", marker.info);
      return markerFeature;
    });

    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: markerFeatures,
      }),
    });
    const url = () => {
      if (user.bedrijf && user.bedrijf.plattegrond && user.bedrijf.plattegrond[0] && user.bedrijf.plattegrond[0].image) return user.bedrijf.plattegrond[0].image;
    };
    const map = new Map({
      layers: [
        new ImageLayer({
          source: new Static({
            url: url(),
            projection: projection,
            imageExtent: extent,
          }),
        }),
        vectorLayer,
      ],
      target: mapContainerRef.current,
      view: new View({
        projection: projection,
        center: getCenter(extent),
        zoom: 2,
        
        maxZoom: 8,
      }),
    });

    mapRef.current = map;

    return () => {
      map.setTarget(null);
    };
  }, [load]);

  useEffect(() => {
    const map = mapRef.current;
    const handleClick = (event) => {
      closePopup();

      const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
      if (feature) {
        const coordinate = feature.getGeometry().getCoordinates();
        const info = feature.get("info");
        const popupContent = document.createElement("div");
        const popup = new Overlay({
          element: popupContent,
          position: coordinate,
          positioning: "top-center",
        });
        const root = createRoot(popupContent);
        root.render(info);
        map.addOverlay(popup);
        popupRef.current = popup;
      } else {
        setpositon(event.coordinate);
      }
    };

    const closePopup = () => {
      if (popupRef.current) {
        const map = mapRef.current;
        map.removeOverlay(popupRef.current);
        popupRef.current = null;
      }
    };
    map.on("click", handleClick);
    return () => {
      map.un("click", handleClick);
    };
  }, [load]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const { value: nameValue } = nameRef.current;
    if (nameValue != "" || position != "") {
      editsensor(nameValue, { position }).then((data) => {
        if (data.type === "SENSOR_ERROR") {
          alert(data.payload);
          return false;
        } else {
          navigate("/sensoren/map/edit");
        }
      });
      nameRef.current.value = "";
    }
  };
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Sensoren</h3>
      </div>

      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">Plaats de sensoren</h3>
        </div>
        <div className="box-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Kies een Sensor</label>
                  <select className="form-control" ref={nameRef}>
                    {load
                      ? sensoren.map((value, key) => {
                          return (
                            <option key={key} value={value._id}>
                              {value.naam}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Plaats de sensor</label>
                  <input type="text" className="form-control" value={position} />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>Plaats de sensor</label>
                  <button type="submit" className="btn btn-primary form-control">
                    Plaats
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {load && <div ref={mapContainerRef} className="map-container" />}
      <Loading loadings={load} />
    </div>
  );
};

function mapStateToProps(state) {
  return { user: state.auth.user, sensoren: state.sensoren.items };
}
export default connect(mapStateToProps, { editsensor, fetchsensoren })(ImageMapEdit);
