
import React, { useState } from "react";
import { required, format } from "redux-form-validators";
import { Field } from "redux-form";
import { renderAFieldSimple } from "../../../Components/Forms/renders";
import switcbutton from "../../../Components/Forms/switch";

const PrijzenForm = ({ fields, aantal }) => {
  const [errors, setErrors] = useState({});

  return (
    <tbody>
      {fields.map((prijzen, index) => (
        <tr key={index}>
          <td>
            <Field
              name={`${prijzen}.naam`}
              type="text"
              validate={format({
                with: /^[a-z]+$/i,
                message: 'Mag slechts een woord zijn',
              })}
              component={renderAFieldSimple}
              label="Naam van de prijsregel"
            />
          </td>
          <td>
            <Field
              name={`${prijzen}.beschrijving`}
              type="text"
              validate={required({ msg: "Dit item is verplicht" })}
              component={renderAFieldSimple}
              label="Beschrijving van de prijsregel"
            />
          </td>
          <td>
            <Field
              name={`${prijzen}.leeftijdvan`}
              type="number"
              component={renderAFieldSimple}
              label="Leeftijd van (mits van toepassing)"
            />
          </td>
          <td>
            <Field
              name={`${prijzen}.leeftijdtot`}
              type="number"
              component={renderAFieldSimple}
              label="Leeftijd tot (mits van toepassing)"
            />
          </td>
          <td>
            <Field
              name={`${prijzen}.verplicht`}
              type="input"
              component={switcbutton}
            />
          </td>
          <td>
            <Field
              name={`${prijzen}.prijs`}
              type="number"
              component={renderAFieldSimple}
              label="Prijs"
            />
          </td>
          <td>
            <button onClick={() => fields.remove(index)}>
              <i className="fa fa-minus-circle"></i>
            </button>
          </td>
        </tr>
      ))}
      <tr>
        <td>
          <button type="button" onClick={() => fields.push({})}>
            Prijsregel Toevoegen
          </button>
        </td>
      </tr>
    </tbody>
  );
};

export default PrijzenForm;