

import React from "react";
import { required } from "redux-form-validators";
import { Field } from "redux-form";
import { renderAFieldSimple, renderselectsimple } from "../../../Components/Forms/renders";
class BeheerderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { fields } = this.props;
    return (
      <tbody>
        {fields.map((beheerders, index) => (
          <tr key={index}>
            <td>
              <Field key={index} name={`${beheerders}.naam`} onChange={this.handleSelect} type="text" validate={required({ msg: "Dit item is verplicht" })} component={renderAFieldSimple} label="Naam van de beheerder" />
            </td>
            <td>
              <Field key={index} name={`${beheerders}.email`} onChange={this.handleSelect} type="email" component={renderAFieldSimple} label="E-mailadres" />
            </td>
            <td>
              <Field key={index} name={`${beheerders}.mobiel`} onChange={this.handleSelect} type="text" component={renderAFieldSimple} label="Telefoonnumer" />
            </td>
            <td>
              <Field
                key={index}
                name={`${beheerders}.voorkeur`}
                component={renderselectsimple}
                options={{
                  sms: "Sms",
                  email: "E-mail",
                }}
                label="Voorkeur communicatie"
              />
            </td>
            <td>
              <button onClick={() => fields.remove(index)}>
                <i className="fa fa-minus-circle"></i>
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <button type="button" onClick={() => fields.push({})}>
              Beheerder Toevoegen
            </button>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default BeheerderForm;
