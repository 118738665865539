import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import HeaderFinancieel from "./Components/header";
import { fetcharrangementen } from "../../Arrangementen/Reducers/actions";
import { fetchervaringen } from "../../Ervaringen/Reducers/actions";
import { saveboekingen, fetchperdatun } from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import { usePDF } from "react-to-pdf";
const FinancieelBoekingen = ({ fetchperdatun, boekingen, username, fetcharrangementen, fetchervaringen, arrangementen, ervaringen }) => {
  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState("accept");
  const [datum, setDatum] = useState({ startdate: moment().subtract(14, "days"), enddate: new Date() });
  const [activiteiten, setActiviteiten] = useState({});
  const { toPDF, targetRef } = usePDF({ filename: "Boekingen.pdf" });
  useEffect(() => {
    fetcharrangementen().then((sdata) => {
      if (sdata.results) {
        fetchervaringen().then((sdata) => {
          if (sdata.results) {
            setLoaded(true);
          }
        });
      }
    });
  }, []);

  const changedata = (vals) => {
    if (vals.datum) {
      setDatum(vals.datum);
      fetchperdatun({ datum: vals.datum, items: activiteiten, status: status }).then((sdata) => {
        if (sdata.results) {
          setLoaded(true);
        }
      });
    } else if (vals.items) {
      setActiviteiten(vals.items);
      fetchperdatun({ datum: datum, items: vals.items, status: status }).then((sdata) => {
        if (sdata.results) {
          setLoaded(true);
        }
      });
    }
  };

  const berekenBoekingFee = (subtotaal, feeValue = 0, feeRegel = null) => {
    return feeRegel === "percentage"
      ? (subtotaal * feeValue) / 100 // Percentage berekening
      : feeRegel === "vast"
      ? parseFloat(feeValue) || 0 // Vast bedrag
      : 0; // Geen boekingsfee
  };
  function berekenTotaalDeelnemers(boekingen) {
    const result = {};

    boekingen.forEach((boeking) => {
      if (boeking.deelnemers && Array.isArray(boeking.deelnemers)) {
        boeking.deelnemers.forEach(({ naam, aantal, prijs }) => {
          if (!result[naam]) {
            result[naam] = { totaalAantal: 0, totaalPrijs: 0 };
          }
          result[naam].totaalAantal += parseInt(aantal, 10); // Tel aantal op
          result[naam].totaalPrijs += parseFloat(aantal) * parseFloat(prijs); // Bereken prijs
        });
      }
    });

    return result;
  }
  const variaties = () => {
    const output = {};

    boekingen.forEach((boeking) => {
      const items = boeking?.arrangementdatum?.ervaring?.items || [];
      const variatieItems = boeking?.variatie?.item || [];

      items.forEach((item, index) => {
        const restaurantNaam = item.naam;

        if (!output[restaurantNaam]) {
          output[restaurantNaam] = {};
        }

        const variatie = variatieItems[index];
        if (variatie) {
          output[restaurantNaam][variatie] = (output[restaurantNaam][variatie] || 0) + boeking.personen;
        }
      });
    });

    return output;
  };
  const restaurants = [...new Set(Object.values(variaties()).flatMap((gerecht) => Object.keys(gerecht)))];

  // Component
  // Bepaal subtotaal en boekingsfee
  const subtotaal = boekingen.filter(({ betaling }) => betaling?.resource != "refund").reduce((acc, { totaalprijs = 0 }) => acc + totaalprijs, 0);
  // Haal fee data veilig op

  const { value: feeValue = 0, regel: feeRegel = null } = boekingen[0]?.arrangementdatum?.ervaring?.reservering?.boekingsfee || boekingen[0]?.ervaringdatum?.ervaring?.reservering?.boekingsfee || {};

  const boekingFee = berekenBoekingFee(subtotaal, feeValue, feeRegel);
  const totaal = subtotaal - boekingFee;

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <HeaderFinancieel changedata={changedata} arrangementen={arrangementen} ervaringen={ervaringen} />
        </div>
      </div>

      {loaded && activiteiten.naam && (
        <div className="box-body box-profile">
          <div className="box-header with-border d-flex">
            <div className="box-body" ref={targetRef}>
              <div className="container">
                <div className="card shadow-lg">
                  {/* Invoice Header */}
                  <div className="card-header text-center bg-green text-white">
                    <h3 className="mb-0">Boekingen {activiteiten.naam} </h3>
                  </div>

                  {/* From/To Section */}
                  <div className="card-body">
                    <div className="row m-4">
                      <div className="col-md-6">
                        <h5 className="mb-3 fw-bold">Van</h5>
                        <div className="ps-3 border-start border-2 border-green">
                          <p className="mb-1">{username.bedrijf.naam}</p>
                          <p className="mb-1">{username.bedrijf.straat}</p>
                          <p className="mb-1">
                            {username.bedrijf.postcode} {username.bedrijf.plaats}
                          </p>
                          <p>Email: {username.bedrijf.email}</p>
                        </div>
                      </div>
                      <div className="col-md-6 text-md-end">
                        <h5 className="mb-3 fw-bold">Aan</h5>
                        <div className="pe-3 border-end border-2 border-green">
                          {(() => {
                            const owner = boekingen[0]?.arrangementdatum?.ervaring?.owner || boekingen[0]?.ervaringdatum?.ervaring?.owner;

                            return (
                              <>
                                <p className="mb-1">{owner?.naam || "Onbekend"}</p>
                                <p className="mb-1">{owner?.straat || "Onbekend"}</p>
                                <p className="mb-1">
                                  {owner?.postcode || ""} {owner?.plaats || ""}
                                </p>
                                <p className="mb-1">Email: {owner?.email || "Onbekend"}</p>
                              </>
                            );
                          })()}
                        </div>
                      </div>
                      <div className="col-12">
                        <h5 className="mb-3 fw-bold">Datum</h5>
                        <div className="ps-3 border-start border-2 border-green">
                          <p className="mb-1">
                            van {moment(datum.startdate).format("DD-MM-YYYY")} -tot {moment(datum.enddate).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Table Section */}
                    <div className="table-responsive m-4">
                      <table className="table table-hover table-bordered">
                        <thead className="table-light">
                          <tr>
                            <th>Order ID</th>
                            <th>Datum</th>
                            <th>Klant</th>
                            <th>Betaald op</th>
                            <th>Betaling</th>
                            <th className="text-end"> Totaal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(boekingen) && boekingen.length > 0 ? (
                            boekingen.map((boeking) => (
                              <tr
                                key={boeking._id}
                                onClick={() => {
                                  if (boeking.soort === "arrangement") {
                                    window.open(`/boekingen/show/arrangement/${boeking._id}`, "_blank", "noopener noreferrer");
                                  } else {
                                    window.open(`/boekingen/show/${boeking._id}`, "_blank", "noopener noreferrer");
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <td>{boeking.OrderID}</td>
                                <td>{moment(boeking.datumactiviteit).format("DD-MM-YYYY")}</td>
                                <td>{boeking.klanten.achternaam}</td>
                                <td>{boeking?.betaling?.paidAt ? moment(boeking?.betaling?.paidAt).format("DD-MM-YYYY") : "N/A"}</td>
                                <td>{boeking?.betaling?.resource === "refund" ? "refund" : boeking?.betaling?.method || "Onbekend"}</td>
                                <td className="text-end">
                                  {(() => {
                                    const settlementValue = parseFloat(boeking?.betaling?.settlementAmount?.value) || 0; // Fallback naar 0
                                    const totaalprijs = parseFloat(boeking.totaalprijs) || 0;

                                    // Controleer op refund en bereken het juiste bedrag
                                    const bedrag = boeking?.betaling?.resource === "refund" ? totaalprijs + settlementValue : totaalprijs;

                                    return bedrag.toLocaleString("nl-NL", {
                                      style: "currency",
                                      currency: "EUR",
                                    });
                                  })()}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center text-muted">
                                Geen boekingen beschikbaar
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <table className="table table-striped table-bordered">
                        <tbody>
                          <tr>
                            <td>Subtotal:</td>
                            <td className="text-end">
                              {subtotaal.toLocaleString("nl-NL", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td>Boekingfee ({feeRegel === "percentage" ? `${feeValue}%` : "€"}):</td>
                            <td className="text-end">
                              {boekingFee.toLocaleString("nl-NL", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </td>
                          </tr>
                          <tr className="fw-bold">
                            <td>Total:</td>
                            <td className="text-end">
                              {totaal.toLocaleString("nl-NL", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="row mt-4 p-4">
                      <div className="col-md-8 text-md-start">
                        <h5 className="mb-3 fw-bold">Totaal van personen</h5>
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>Deelnemer</th>
                              <th className="text-end">Aantal</th>
                              <th className="text-end">Totaal Prijs</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(berekenTotaalDeelnemers(boekingen)).map(([naam, { totaalAantal, totaalPrijs }]) => (
                              <tr key={naam}>
                                <td>{naam}</td>
                                <td className="text-end">{totaalAantal}</td>
                                <td className="text-end">
                                  {totaalPrijs.toLocaleString("nl-NL", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {Object.entries(variaties()).length > 0 && (
                      <div className="row mt-4 p-4">
                        <div className="col-md-8 text-md-start">
                          <h5 className="mb-3 fw-bold">Variaties</h5>
                          <table className="table table-bordered">
                            <thead className="table-light">
                              <tr>
                                <th>Gerecht</th>
                                {restaurants.map((restaurant, index) => (
                                  <th key={index}>{restaurant}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(variaties()).map(([gerecht, waarden], index) => (
                                <tr key={index}>
                                  <td>{gerecht}</td>
                                  {restaurants.map((restaurant, i) => (
                                    <td key={i}>{waarden[restaurant] || 0}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Footer */}
                  <div className="card-footer bg-light text-center">
                    <p className="mb-0">Bedankt voor de samenwerking!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer">
            <button className="btn btn-primary" onClick={toPDF}>
              Download PDF
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, ervaringen: state.ervaringen.items, boekingen: state.boekingen.items, arrangementen: state.arrangementen.items };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  fetchervaringen,
  saveboekingen,
  fetchperdatun,
  fetcharrangementen,
})(FinancieelBoekingen);
