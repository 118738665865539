import React from "react";
import { Field } from "react-final-form";
import { renderFieldsimple } from "./renders";
class Textinputarray extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { fields, label } = this.props;

    return (
      <div>
        <label>{label}</label>
        <table className="table">
          <tbody className="w-100 p-3">
            {fields.map((item, index) => (
              <tr key={item}>
                <td>
                  <Field
                    component={renderFieldsimple}
                    key={index}
                    name={`${item}.text`}
                    onChange={this.handleSelect}
                  />
                </td>
                <td>
                  <button onClick={() => fields.remove(index)}>
                    <i className="fa fa-minus-circle"></i>
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <button type="button" onClick={() => fields.push({})}>
                Item Toevoegen
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Textinputarray;
