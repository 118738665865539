
import React, { useState } from "react";
import { required, format } from "redux-form-validators";
import { Field } from "redux-form";
import { renderAFieldSimple } from "../../../Components/Forms/renders";
import timeSelect from "../../../Components/Forms/time";

const TijdsslotenForm = ({ fields, aantal }) => {
  const [errors, setErrors] = useState({});

  return (
    <tbody>
      {fields.map((tijdssloten, index) => (
        <tr key={index}>
          <td>
            <Field label="Kies starttijd" component={timeSelect} name={`${tijdssloten}.tijd`} simple={true} />
          </td>
          <td>
            <Field name={`${tijdssloten}.min`} type="number" component={renderAFieldSimple} label="Minmaal anntal (0 = ombeperkt)" />
          </td>
          <td>
            <Field name={`${tijdssloten}.max`} type="number" component={renderAFieldSimple} label="Minmaal anntal (0 = ombeperkt)" />
          </td>

          <td>
            <button type="button" onClick={() => fields.remove(index)}>
              <i className="fa fa-minus-circle"></i>
            </button>
          </td>
        </tr>
      ))}
      <tr>
        <td>
          <button type="button" onClick={() => fields.push({})}>
            Tijddslot Toevoegen
          </button>
        </td>
      </tr>
    </tbody>
  );
};

export default TijdsslotenForm;
