import React from "react";
import { Field } from "redux-form";
import { renderAField } from "../../../Components/Forms/renders";
import Wysiwyg from "../../../Components/Forms/wysiwyg";

const FAQ = ({ fields }) => {
  return (
    <div className="faq-container">
      {fields.map((faq, index) => (
        <div key={index} className="faq-item border p-3 mb-3">
          <div className="mb-3">
            <Field name={`${faq}.vraag`} type="text" component={renderAField} label="Vraag" className="form-control" />
          </div>
          <div className="mb-3">
            <Field name={`${faq}.antwoord`} type="textarea" component={Wysiwyg} label="Antwoord" className="form-control" normalize={(value) => value.toString("html")} />
          </div>
          <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-danger btn-sm" onClick={() => fields.remove(index)}>
              <i className="fa fa-minus-circle me-2"></i> Verwijderen
            </button>
          </div>
        </div>
      ))}
      <div className="text-start mt-4">
        <button type="button" className="btn btn-sm btn-primary" onClick={() => fields.push({})}>
          <i className="fa fa-plus-circle me-2"></i> Nieuwe FAQ Toevoegen
        </button>
      </div>
    </div>
  );
};

export default FAQ;
