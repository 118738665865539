

import React from "react";
import { required, format } from "redux-form-validators";
import { Field } from "redux-form";
import { renderAFieldSimple, renderselectsimple } from "../../../Components/Forms/renders";
class optiesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { fields, items } = this.props;

    return (
      <tbody>
        {fields.map((opties, index) => (
          <tr key={index}>
            <td>
              <Field name={`${opties}.startpunt`} type="text" validate={required({ msg: "Dit item is verplicht" })} options={Object.fromEntries(items.startpuntlocatie.map((location) => [location.label.trim(), location.label.trim()]))} component={renderselectsimple} label="Kies de startlocatie" />
            </td>
            {items.items.map((item, indexo) => (
              <td>
                <Field
                  key={indexo}
                  name={`${opties}.item[${indexo}]`}
                  type="text"
                  validate={required({ msg: "Dit item is verplicht" })}
                  options={Object.fromEntries(item.locaties.map((location) => [location.label.trim(), location.label.trim()]))}
                  component={renderselectsimple}
                  label="Kies een locatie"
                />
              </td>
            ))}
            <td>
              <Field
                name={`${opties}.favoriet`}
                type="text"
                options={{
                  true: "Ja",
                  false: "Nee",
                }}
                component={renderselectsimple}
                label="Favorite"
              />
            </td>
            <td>
              <button onClick={() => fields.remove(index)}>
                <i className="fa fa-minus-circle"></i>
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <button type="button" onClick={() => fields.push({})}>
              Variatie regel Toevoegen
            </button>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default optiesForm;
