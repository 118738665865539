import React from "react";
import { Field, reduxForm, getFormValues, FieldArray } from "redux-form";
import { connect } from "react-redux";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import SwitcButton from "../../../Components/Forms/switch";
import FAQ from "./faq";

const LogboekFormWebsite = ({ handleSubmit, formValues }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="card">
        <div className="card-header">Aanvullende informatie naar gasten</div>
        <div className="card-body">
          <div>
            <Field name="communicatie.reserveringaanvraag" type="textarea" component={Wysiwyg} label="Teksten bij de reserveringsaanvraag" className="form-control" normalize={(value) => value.toString("html")} />
            <Field name="communicatie.bevestiging" type="textarea" component={Wysiwyg} label="Teksten bij de bevestiging van de reserveringsaanvraag" className="form-control" normalize={(value) => value.toString("html")} />
            <Field name={`communicatie.voorwaarde`} component={SwitcButton} label="Moet de voorwaarden meegestuurd worden met de bevestiging?" />
            <Field name="communicatie.betaling" type="textarea" component={Wysiwyg} label="Teksten bij een reservering waarbij gasten moeten betalen" className="form-control" normalize={(value) => value.toString("html")} />
            <Field name="communicatie.cancel" type="textarea" component={Wysiwyg} label="Teksten bij het afwijzen van de reserveringsaanvraag" className="form-control" normalize={(value) => value.toString("html")} />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">FAQ</div>
        <div className="card-body">
          <div>
            <FieldArray name="faq" component={FAQ} />
          </div>
        </div>
      </div>
      <div className="card-footer text-muted m-2">
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
      </div>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(LogboekFormWebsite);

const mapStateToProps = (state) => {
  const defaultValues = {
    communicatie: {
      bevestiging: "Hierbij sturen we je de bevestiging van je reserveringsaanvraag.",
      reserveringsaanvraag: "Heb je vragen over je reserveringsaanvraag? Neem dan contact met ons op.",
      annulering: "Helaas is je reserveringsaanvraag afgewezen. Dit betekent dat je aanvraag niet doorgaat. Zoek een nieuwe ervaring of kies een andere datum, mits dat mogelijk is.",
    },
  };

  const initialValues = {
    ...defaultValues,
    ...state.arrangementen.item,
    communicatie: {
      ...defaultValues.communicatie,
      ...(state.arrangementen.item.communicatie || {}),
    },
  };

  return {
    initialValues,
    formValues: getFormValues("categorie_edit")(state),
  };
};

export default connect(mapStateToProps)(ReduxFormSensorEdit);
