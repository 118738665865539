import { createRoot } from "react-dom/client";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Map from "ol/Map.js";
import View from "ol/View.js";
import ImageLayer from "ol/layer/Image.js";
import Overlay from "ol/Overlay.js";
import Static from "ol/source/ImageStatic.js";
import Projection from "ol/proj/Projection.js";
import { getCenter } from "ol/extent.js";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import { Icon, Style } from "ol/style.js";
import { fetchsensoren } from "../Reducers/actions";
import moment from "moment";
import Loading from "../../../Components/loading/load";
import laatstedatafunctie from "./functies";
const ImageMap = ({ fetchsensoren, sensoren, user }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const popupRef = useRef(null);
  const [load, setLoad] = useState(false);
  const [tijdload, settijdload] = useState(new Date());
  useEffect(() => {
    const fetchData = () => {
      if (user.bedrijf && user.bedrijf.plattegrond) {
        fetchsensoren().then((data) => {
          setLoad(true);
          settijdload(new Date());
        });
      } else {
        setLoad(false);
      }
    };
    fetchData(); // Directe uitvoering van de functie
    const intervalId = setInterval(fetchData, 60000); // Start het interval na de eerste uitvoering
    return () => {
      clearInterval(intervalId);
    };
  }, [user]);

  useEffect(() => {
    if (load) {
      const extent = [0, 0, 1024, 968];
      const projection = new Projection({
        code: "xkcd-image",
        units: "pixels",
        extent: extent,
      });

      const markerData = sensoren
        .filter((value) => value.position && value.position[0] && value.position[1])
        .map((value, key) => ({
          position: [value.position[0], value.position[1]],
          icon: value.status !== undefined && value.status === "false" ? "/img/marker-bad.png" : "/img/marker-good.png",
          height: value.status !== undefined && value.status === "false" ? 50 : 30,
          width: value.status !== undefined && value.status === "false" ? 50 : 30,
          info: (
            <div className="popup-content">
              <strong className="titelpopup">{value.naam}</strong>
              <div>Laatste data: <strong>{laatstedatafunctie(value)}</strong></div>
              <small>{formatDate(value.lastcheck)}</small>
            </div>
          ),
        }));
      const markerFeatures = markerData.map((marker) => {
        const markerFeature = new Feature({
          geometry: new Point(marker.position),
        });

        const markerStyle = new Style({
          image: new Icon({
            src: marker.icon,
            height: marker.height,
            width: marker.width,
            anchor: [0.5, 1],
          }),
        });

        markerFeature.setStyle(markerStyle);
        markerFeature.set("info", marker.info);
        return markerFeature;
      });
      function blink() {
        markerFeatures.forEach((markerFeature) => {
          const icon = markerFeature.getStyle().getImage();
  
          if (icon.getSrc() === "/img/marker-bad.png") {
            const currentOpacity = icon.getOpacity();
  
            // Wissel de opacity
            icon.setOpacity(currentOpacity === 1 ? 0 : 1);
  
            // Forceer een her-rendering van de laag
            markerFeature.changed();
          }
        });
  
        // Herhaal de functie na het interval
        setTimeout(blink, 500);
      }
  
      // Begin het knipperen
      blink();

      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: markerFeatures,
        }),
      });
      const url = () => {
        if (user.bedrijf && user.bedrijf.plattegrond && user.bedrijf.plattegrond[0] && user.bedrijf.plattegrond[0].image) {
          return user.bedrijf.plattegrond[0].image;
        }
      };

      const map = new Map({
        layers: [
          new ImageLayer({
            source: new Static({
              url: url(),
              projection: projection,
              imageExtent: extent,
            }),
          }),
          vectorLayer,
        ],
        target: mapContainerRef.current,
        view: new View({
          projection: projection,
          center: getCenter(extent),
          zoom: 2,
          maxZoom: 8,
        }),
      });

      mapRef.current = map;
      return () => {
        if (load) map.setTarget(null);
      };
    }
  }, [load, sensoren]);

  useEffect(() => {
    const map = mapRef.current;
    const handleClick = (event) => {
      closePopup();

      const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
      if (feature) {
        const coordinate = feature.getGeometry().getCoordinates();
        const info = feature.get("info");

        const popupContent = document.createElement("div");
        const popup = new Overlay({
          element: popupContent,
          position: coordinate,
          positioning: "top-center",
        });

        const root = createRoot(popupContent);
        root.render(info);

        map.addOverlay(popup);
        popupRef.current = popup;
      }
    };

    const closePopup = () => {
      if (popupRef.current) {
        const map = mapRef.current;
        map.removeOverlay(popupRef.current);
        popupRef.current = null;
      }
    };
    if (load) {
      map.on("click", handleClick);
    }

    return () => {
      if (load) {
        map.un("click", handleClick);
      }
    };
  }, [load]);
  const toggleFullscreen = () => {
    if (mapContainerRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        mapContainerRef.current.requestFullscreen();
      }
    }
  };
  const formatDate = (date) => {
    const now = moment();
    const formattedTime = moment(date).format("HH:mm");

    if (moment(date).isSame(now, "day")) {
      return `vandaag om ${formattedTime}`;
    } else {
      return moment(date).format("LL [om] HH:mm");
    }
  };
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Sensoren</h3>
        <div className="box-tools pull-right">
          <button className="btn btn-warning btn-sm float-right" onClick={toggleFullscreen}>
            Fullscreen
          </button>
          <Link className="btn btn-warning btn-sm float-right" role="group" to={"/sensoren/map/edit/"}>
            Wijzigen
          </Link>
        </div>
      </div>
      {load && <div ref={mapContainerRef} className="map-container" />}
      <Loading loadings={load} />
      <span className="badge badge-secondary">De gegevens zijn geladen op: ({formatDate(tijdload)})</span>
    </div>
  );
};

function mapStateToProps(state) {
  return { user: state.auth.user, sensoren: state.sensoren.items };
}
export default connect(mapStateToProps, { fetchsensoren })(ImageMap);
